import gql from "graphql-tag";

export const getProductsQuery = gql`
  query getProducts {
    getProducts {
      _id
      name
      quantity
      price
      subTotal
      eventDate
      time
    }
  }`

export const getProductQuery = gql`
  query getProduct($productId: String!) {
    getProduct(productId: $productId) {
      _id
      name
      quantity
      price
      subTotal
      eventDate
      time
      updatedAt
    }
  }`

export const updateProductMutation = gql`
  mutation updateProduct($product: ProductInput!) {
    updateProduct(product: $product) {
        _id
        name
        quantity
        price
        time
        eventDate
    }
  }`

export const deleteProductMutation = gql`
  mutation deleteProduct($productId: String!) {
    deleteProduct(productId: $productId) {
      acknowledged
      deletedCount
    }
  }`

export const createProductMutation = gql`
  mutation createProduct($product: ProductInput!) {
    createProduct(product: $product) {
      _id
      name
      quantity
      price
      time
      eventDate
    }
  }`