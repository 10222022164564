import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "@/views/public/HomePage.vue";
import CheckoutPage from "@/views/public/CheckoutPage.vue";
import OrderCompletePage from "@/views/public/OrderCompletePage.vue";
import OrderPage from "@/views/public/OrderPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutPage,
  },
  {
    path: "/order-complete/:orderId",
    name: "OrderComplete",
    component: OrderCompletePage,
  },
  {
    path: "/order/:orderId",
    name: "Order",
    component: OrderPage
  },
  // {
  //   path: '/:catchAll(.*)*',
  //   name: "PageNotFound",
  //   component: PageNotFound,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
