
  import { defineComponent, computed } from "vue";
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  export default defineComponent({
    name: "App",
    setup() {
      const { result, loading, error } = useQuery(gql`
        query getSettings {
          getSettings {
            _id
            cartOn
            title
          }
        }
      `, null, {
        pollInterval: 5000,
        fetchPolicy: 'no-cache'
      })

      const settings = computed(() => result.value?.getSettings ?? {})
      
      return {
        settings,
        loading,
        error,
      }
    },
  });
