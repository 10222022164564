import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d60f013"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "tickets"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductElement = _resolveComponent("ProductElement")!

  return (Object.keys(_ctx.products).length !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "ticket",
            key: product._id
          }, [
            _createVNode(_component_ProductElement, {
              eventDate: product.eventDate,
              id: product._id,
              time: product.time,
              name: product.name,
              price: Number(product.price.$numberDecimal),
              productQuantity: Number(product.quantity),
              forceRefresh: _ctx.productRefresh,
              onUpdateEvent: _ctx.updateEvent
            }, null, 8, ["eventDate", "id", "time", "name", "price", "productQuantity", "forceRefresh", "onUpdateEvent"])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}