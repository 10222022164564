
  import moment from "moment";
  import { useQuery } from "@vue/apollo-composable";
  import { defineComponent, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import SiteHeader from '@/components/public/SiteHeader.vue';
  import { getOrderQuery } from '@/graphql';

  export default defineComponent({
    name: "OrderCompletePage",
    components: {
      SiteHeader
    },
    props: {
      settings: Object
    },
    setup () {
      const route = useRoute()
      const router = useRouter();

      if (route.params.orderId === undefined) {
        router.push({ name: 'Home' })
      }

      const { result, loading, error } = useQuery(getOrderQuery, {
          orderId: route.params.orderId
        }
      ) 

      const order = computed(() => result.value?.getOrder ?? {})

      return {
        order,
        loading,
        error,
      }
    },
    methods: {
      formatDate: (date: Date) => {
        return moment(date).format('MMMM D, YYYY');
      }
    }
  });
