
  import '@stripe/stripe-js'
  import { isNil, reduce } from 'lodash';
  import { defineComponent, ref } from "vue";
  import { useMutation } from "@vue/apollo-composable";
  import { useStripe, StripeElement } from 'vue-use-stripe'
  import { useRouter } from 'vue-router';
  import SiteHeader from '@/components/public/SiteHeader.vue';
  import SpinnerElement from "@/components/shared/SpinnerElement.vue";
  import CartSummary from "@/components/public/CartSummary.vue";
  import { 
    createOrderMutation,
    createStripePaymentIntentMutation
  } from '@/graphql';

  export default defineComponent({
    name: "CheckoutPage",
    components: { 
      SiteHeader,
      StripeElement,
      SpinnerElement,
      CartSummary,
    },
    props: {
      settings: Object
    },
    setup () {
      const firstName = ref();
      const lastName = ref();
      const email = ref();
      const phone = ref();
      const line1 = ref();
      const line2 = ref();
      const city = ref();
      const state = ref();
      const postalCode = ref();
      const cartTotal = ref(0);
      const productCount = ref(0);
      const transactionFee = ref(0);
      const router = useRouter();
      const event = ref()
      const checkoutError = ref();
      const isLoading = ref(false);
      const cart = ref();

      const {
        stripe,
        elements: [cardElement],
      } = useStripe({
        key: process.env.VUE_APP_STRIPE_PUBLIC_KEY || '',
        elements: [{ type: 'card', options: {} }],
      })

      const { mutate: createOrder, onDone, onError } = useMutation(createOrderMutation, {
        fetchPolicy: 'no-cache'
      });

      const { mutate: createPaymentIntent, loading: loadingPaymentIntent } = useMutation(createStripePaymentIntentMutation, {
        fetchPolicy: 'no-cache'
      });

      onDone(order => {
        if (!order.data.error) {
          router.push({ 
            name: 'OrderComplete',
            params: { 
              orderId: order.data.createOrder._id 
            }
          });
        } else {
          isLoading.value = false;
          checkoutError.value = order.data.error.message;
        }
      })

      onError(error => {
        isLoading.value = false;
        checkoutError.value = error;
      })

      const checkout = async (e: any) => {
        e.preventDefault();
        
        checkoutError.value = null;
        isLoading.value = true;

        if (isNil(event.value)) {
          checkoutError.value = 'You must enter a valid credit card number.';
          isLoading.value = false;
          return;
        }

        if (!event.value?.complete) {
          checkoutError.value = `Missing Credit Card Info.`;
          isLoading.value = false;
        }

        if (event.value?.complete) {
          let paymentIntentClientSecret = '';
          const billingDetails =  {
            address: {
              line1: line1.value,
              line2: line2.value,
              city: city.value,
              state: state.value + ', US',
              postal_code: postalCode.value
            },
            email: email.value,
            phone: phone.value,
            name: firstName.value + ' ' + lastName.value,
          };

          const transactionAmount = ((cart.value.subTotal + .30) / (1 - .029)) * 100;

          const paymentIntent = await createPaymentIntent({
            input: {
              customer: billingDetails,
              amount:  Math.ceil(transactionAmount)
            }
          });

          paymentIntentClientSecret = paymentIntent?.data?.createStripePaymentIntent.clientSecret;

          const cardSetup = await stripe.value?.confirmCardPayment(paymentIntentClientSecret, {
            payment_method: {
              card: cardElement.value,
              billing_details: billingDetails
            },
            setup_future_usage: 'on_session'
          });

          if (cardSetup?.error) {
            isLoading.value = false;
            checkoutError.value = cardSetup.error?.message;
            return;
          }

          const orderVars = {
            order: {
              customer: {
                firstName: firstName.value,
                lastName: lastName.value,
                address: {
                  line1: line1.value,
                  line2: line2.value,
                  city: city.value,
                  stateOrProvince: state.value,
                  postalCode: postalCode.value,
                  country: "US"
                },
                email: email.value,
                phone: phone.value,
              },
              paymentIntentId: cardSetup?.paymentIntent.id
            }
          };

          return await createOrder(orderVars);
        }

      }

      const cartContents = (data: any) => {
        if (data.products.length === 0) {
          router.push({ name: 'Home' });
        } else {
          cart.value = data;
        }
      };

      const cartUpdate = (event: any) => {
        if (event.products.length === 0) {
          router.push({ name: 'Home' })
        }
      };

      return {
        cartContents,
        cartUpdate,
        checkout,
        cardElement,
        event,
        checkoutError,
        isLoading,
        firstName,
        lastName,
        email,
        phone,
        line1,
        line2,
        city,
        state,
        postalCode,
        cartTotal,
        productCount,
        transactionFee,
        cart
      }
    },
  });
