import gql from "graphql-tag";

export const getSettingsQuery = gql`
  query getSettings {
    getSettings {
      _id
      cartOn
      title
    }
  }`;

export const updateSettingsMutation = gql`
  mutation updateSettings($setting: SettingInput!) {
    updateSettings(setting: $setting) {
      cartOn
      title
    }
  }`