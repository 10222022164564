
  import moment from "moment";
  import { defineComponent, ref, watch } from "vue";
  import { useMutation } from "@vue/apollo-composable";
  import { removeFromCartMutation } from '@/graphql';

  export default defineComponent({
    name: "CartProduct",
    props: {
      id: String,
      eventDate: String,
      time: String,
      name: String,
      price: [Object, Number],
      productQuantity: Number,
    },
    emit: ['updateEvent'],
    setup (props, context) {
      const isRemoved = ref(false);
      const rawEventDate = ref(moment(props.eventDate).format('MMMM D, YYYY') || moment());
      const productPrice = ref((typeof props.price === 'object' ? props.price.$numberDecimal : props.price) || 0);
      const totalQuantity = ref(props.productQuantity || 0);

      const { mutate: removeFromCart, onDone } = useMutation(removeFromCartMutation, () => ({
        variables: {
          productId: props.id
        },
      }))

      onDone(result => {
        context.emit('updateEvent', result.data.removeFromCart);
        isRemoved.value = true;
      });

      watch(() => props.productQuantity, (newValue, oldValue) => {
        totalQuantity.value = Number(props.productQuantity);
        productPrice.value = (typeof props.price === 'object' ? props.price.$numberDecimal : props.price);
      });

      return {
        removeFromCart,
        isRemoved,
        rawEventDate,
        productPrice,
        totalQuantity
      }
    },
    methods: {}
  });
