
  import moment from "moment";
  import { defineComponent, ref, Ref, watch } from "vue";
  import { useMutation } from "@vue/apollo-composable";
  import { addToCartMutation } from '@/graphql';
  import SpinnerElement from "@/components/shared/SpinnerElement.vue";

  export default defineComponent({
    components: { 
      SpinnerElement,
    },
    name: "ProductElement",
    emit: ['updateEvent'],
    props: {
      id: String,
      eventDate: String,
      time: String,
      name: String,
      price: [Object, Number],
      productQuantity: Number,
      forceRefresh: Number
    },
    setup(props, context) {
      const quantity = ref(1);
      const message = ref();
      const totalQuantity = ref(props.productQuantity || 0)
      const productPrice = ref((typeof props.price === 'object' ? props.price.$numberDecimal : props.price) || 0);
      const rawEventDate = ref(moment(props.eventDate).format('MMMM D, YYYY') || moment());
      const isLoading = ref(false);

      const { mutate: addToCart, onDone, onError, loading } = useMutation(addToCartMutation, () => ({
        variables: {
          item: {
            productId: props.id,
            quantity: Number(quantity.value),
          }
        },
      }))

      const addProductToCart = () => {
        if (quantity.value > 0) {
          isLoading.value = true;
          addToCart();
          message.value = '';
        } else {
          message.value = "You must provide a quantity.";
        }
      }

      onDone(result => {
        if (result.data.addToCart.message) {
          message.value = result.data.addToCart.message;
        } else {
          setTimeout(() => {
            message.value = result.data.addToCart.message;
            context.emit('updateEvent', result);
            isLoading.value = false;
          }, 1000);
        }
      });

      onError(error => {
        console.log(error);
      })

      watch(() => props.forceRefresh, (newValue, oldValue) => {
        totalQuantity.value = Number(props.productQuantity);
      });


      return {
        quantity,
        addToCart,
        loading,
        addProductToCart,
        message,
        totalQuantity,
        productPrice,
        rawEventDate,
        isLoading
      }
    },
  });
