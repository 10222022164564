import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66c30362"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cart-ticket"
}
const _hoisted_2 = { class: "name" }
const _hoisted_3 = { class: "price-qantity" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isRemoved)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name) + ", " + _toDisplayString(_ctx.rawEventDate), 1),
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.totalQuantity), 1),
          _createTextVNode(" x $" + _toDisplayString(_ctx.productPrice), 1)
        ]),
        _createElementVNode("span", {
          class: "remove",
          title: "Remove",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeFromCart()))
        }, "x")
      ]))
    : _createCommentVNode("", true)
}