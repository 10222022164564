
  import { defineComponent, computed, ref } from "vue";
  import { useQuery } from "@vue/apollo-composable";
  import ProductElement from "@/components/public/ProductElement.vue";
  import { getProductsQuery } from "@/graphql";

  export default defineComponent({
    components: { 
      ProductElement,
    },
    name: "ProductList",
    emit: ['updateEvent', 'productsLoaded'],
    props: {
      forceRefresh: Number
    },
    setup(props, context) {
      const productRefresh = ref(0);

      const { result, onResult, onError, refetch } = useQuery(getProductsQuery, null, {
        pollInterval: 10000,
        fetchPolicy: 'no-cache'
      })

      const products = computed(() => result.value?.getProducts ?? {})

      onResult(products => {
        productRefresh.value += 1;
        context.emit('productsLoaded', true);
      });

      onError(error => {
        console.log(error);
      });

      const updateEvent = () => {
        refetch();
        context.emit('updateEvent');
      }

      return {
          updateEvent,
          products,
          productRefresh
      }
    },
  });
