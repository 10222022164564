
  import { defineComponent, ref } from "vue";
  import SiteHeader from '@/components/public/SiteHeader.vue';
  import ProductList from "@/components/public/ProductList.vue";
  import CartSummary from "@/components/public/CartSummary.vue";
  import { sumBy, isEmpty } from 'lodash';

  export default defineComponent({
    name: "HomePage",
    components: {
      SiteHeader,
      ProductList,
      CartSummary,
    },
    props: {
      settings: Object
    },
    setup() {
      const productsLoaded = ref(false);
      const products = ref([]);
      const cartForceRefresh = ref(0);
      const productsForceRefresh = ref(0);
      const totalProductCount = ref(0);
      const isCartActive = ref(false);
      const isCartExpired = ref(false);

      const setCartContents = (event: any) => {
        products.value = event.products;
        const cartTotal = sumBy(event.products, 'quantity');
        totalProductCount.value = cartTotal;

        if (cartTotal > 0) {
          isCartActive.value = true;
        }
      }

      const cartUpdate = () => {
        cartForceRefresh.value += 1;
      }

      const productsRefresh = () => {
        productsForceRefresh.value += 1;
      }

      const toggleCart = () => {
        isCartActive.value = isCartActive.value === true ? false : true;
      }

      const alertExpiredCart = () => {
        isCartExpired.value = true;
      }

      return {
        alertExpiredCart,
        setCartContents,
        productsRefresh,
        cartUpdate,
        products,
        productsLoaded,
        cartForceRefresh,
        productsForceRefresh,
        totalProductCount,
        isCartActive,
        toggleCart,
        isCartExpired,
        isEmpty
      }
    },
  });
