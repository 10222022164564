
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "SpinnerElement",
    props: {
      text: String,
      size: String
    },
  });
