
  import { defineComponent, computed, watch, ref } from "vue";
  import CartProduct from '@/components/public/CartProduct.vue';
  import SpinnerElement from "@/components/shared/SpinnerElement.vue";
  import { useQuery } from "@vue/apollo-composable";
  import { getCartQuery } from '@/graphql';
  import moment from 'moment';

  export default defineComponent({
    components: {
      CartProduct,
      SpinnerElement
    },
    name: "CartSummary",
    emit: ['updateEvent', 'cart', 'cartExpired'],
    props: {
      forceRefresh: Number
    },
    setup(props, context) {
      const timer = ref('00:00');
      const interval = ref();
      const expiration = ref();
      const isExpired = ref(false);

      const startTimer = () => {
        const duration = moment.duration(moment(expiration.value).diff(moment()), 'milliseconds');
        timer.value = moment(duration.asMilliseconds()).format('mm:ss');

        if (duration.asMinutes() <= 0) {
          clearInterval(interval.value);
          context.emit('cartExpired', true);
          isExpired.value = true;
        }
      }

      const { result, refetch, onResult, onError, loading } = useQuery(getCartQuery, null, {
        fetchPolicy: 'no-cache'
      });

      const cart = computed(() => result.value?.getCart ?? { products: []})

      onResult(result => {
        context.emit('cart', result.data.getCart);
        expiration.value = result.data.getCart.expiration;

        if (interval.value === undefined) {
          interval.value = setInterval(startTimer, 1000);
        }
      })

      onError(error => {
        console.log(error);
      })

      const update = (event: any) => {
        refetch();
        context.emit('updateEvent', result.value?.getCart)
      }

      watch(() => props.forceRefresh, (newValue, oldValue) => {
        refetch();
      });

      return {
        update,
        cart,
        loading,
        timer,
        isExpired
      }
    }
  });
