import gql from "graphql-tag";

export const getCartQuery = gql`
  query getCart {
    getCart{
      products {
        _id
        name
        quantity
        price
        subTotal
        eventDate
      }
      expiration
      subTotal
      stripe {
        setupIntent {
          id
          clientSecret
        }
      }
    }
  }
`

export const removeFromCartMutation = gql`
  mutation removeFromCart($productId: String!) {
      removeFromCart(productId: $productId) {
          products {
              _id
              name
              quantity
              price
              subTotal
          }
          subTotal
      }
  }
`

export const addToCartMutation = gql`
  mutation addToCart($item: ItemInput!) {
    addToCart(item: $item) {
      products {
        _id
        name
        quantity
        price
        subTotal
      }
      subTotal
      message
    }
  }
`