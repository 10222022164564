import gql from "graphql-tag";

const orderFields = `
  _id
  products {
    name
    quantity
    checkIns
    originalQuantity
    price
    subTotal
    time
    eventDate
    updatedAt
    deletedAt
  }
  customer {
    firstName
    lastName
    address {
      line1
      line2
      city
      stateOrProvince
      postalCode
      country
    }
    email
    phone
    stripe {
      customerId
    }
    updatedAt
    deletedAt
  }
  orderTotal
  transactionFee
  stripe {
    paymentMethodId
    paymentIntentId
  }
  status
  paymentType
  refunds {
    amount
    reason
    memo
    stripeId
    createdAt
  }
  updatedAt
  createdAt
`;

export const getOrdersQuery = gql`
  query getOrders($search: String) {
    getOrders(search: $search) {
      ${orderFields}
    }
  }
`;

export const getOrderQuery = gql`
  query getOrder($orderId: String!) {
    getOrder(orderId: $orderId) {
      ${orderFields}
    }
  }
`;

export const createOrderMutation = gql`
  mutation createOrder($order: OrderInput!) {
    createOrder(order: $order) {
      ${orderFields}
    }
  }
`;

export const createAdminOrderMutation = gql`
  mutation createAdminOrder($order: AdminOrderInput!) {
    createAdminOrder(order: $order) {
      ${orderFields}
    }
  }
`;

export const getStripeSetupIntentQuery = gql`
  query getStripeSetupIntent {
    getStripeSetupIntent {
      id
      clientSecret
    }
  }
`;

export const createStripePaymentIntentMutation = gql`
  mutation createStripePaymentIntent($input: StripePaymentIntentInput!) {
    createStripePaymentIntent(input: $input) {
      id
      clientSecret
    }
  }
`;

export const cancelStripeSetupIntentQuery = gql`
  mutation cancelStripeSetupIntent($id: String!) {
    cancelStripeSetupIntent(id: $id) {
      id
      clientSecret
    }
  }
`;

export const refundOrderMutation = gql`
  mutation refundOrder($refund: RefundInput!) {
    refundOrder(refund: $refund)
  }
`;

export const adjustOrderMutation = gql`
  mutation adjustOrder($input: AdjustOrderInput!) {
    adjustOrder(input: $input) {
      ${orderFields}
    }
  }
`;