
  import { sumBy } from 'lodash';
  import moment from "moment";
  import { defineComponent, ref, computed } from "vue";
  import { useRoute } from "vue-router";
  import { useQuery } from "@vue/apollo-composable";
  import QrcodeVue from 'qrcode.vue'
  import SiteHeader from '@/components/public/SiteHeader.vue';
  import { getOrderQuery } from '@/graphql';

  export default defineComponent({
    name: "OrderPage",
    components: {
      SiteHeader,
      QrcodeVue,
    },
    props: {
      settings: Object
    },
    setup () {
      const route = useRoute()
      const qrcodeValue = ref(route.params.orderId);

      const { result, loading, error } = useQuery(getOrderQuery, {
        orderId: route.params.orderId
      })

      const order = computed(() => result.value?.getOrder ?? {})

      const refundTotal = (order: any) => {
        return sumBy(order.refunds, 'amount');
      }

      return {
        order,
        loading,
        error,
        qrcodeValue,
        refundTotal
      }
    },
    methods: {
      formatDate: (date: Date) => {
        return moment(date).format('MMMM D, YYYY');
      }
    }
  });
