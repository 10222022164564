import gql from "graphql-tag";

const userFields = `
  _id
  username
  email
  name
  role
`;

export const loginQuery = gql`
  query login($credentials: UserLoginInput!) {
    login(credentials: $credentials) {
      ${userFields}
    }
  }`;

export const isAuthenticatedQuery = gql`
  query isAuthenticated {
    isAuthenticated
  }
`;

export const createUserMutation = gql`
  mutation createUser($user: UserInput!) {
    createUser(user: $user) {
      ${userFields}
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      ${userFields}
    }
  }
`;

export const getUsersQuery = gql`
  query getUsers {
    getUsers {
      ${userFields}
    }
  }
`;

export const getUserQuery = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      ${userFields}
    }
  }
`;

export const getCurrentUserQuery = gql`
  query getCurrentUser {
    getCurrentUser {
      ${userFields}
    }
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      acknowledged
      deletedCount
    }
  }
`;